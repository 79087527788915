import { React } from "react";
import ProductCart from "./ProductCart/ProductCart";
import SimilarPatterns from "./SimilarPatterns/SimilarPatterns"
import Reviews from "./Reviews/Reviews";
import Morebrands from "./Morebrands/Morebrands";
const ProductDescription = () => {

    return (
        <>
            <ProductCart />
            <Reviews />
            <Morebrands />
            <SimilarPatterns heading={"Similar Patterns for you"} />
            <SimilarPatterns heading={"Crafted for you"} />
            
        </>
    );
};

export default ProductDescription;
