import React, { useState, useEffect, useContext } from "react";
import "./Category.css";
import { Row, Col, Container } from "react-bootstrap";
import { Context } from "../../../utils/context";
import { allCategory } from "../../../utils/apis/common/Common";
import { Link, useNavigate } from "react-router-dom";
import { Swiper, SwiperSlide } from "swiper/react";
// import ButtonComponent from "../../CommonComponents/ButtonComponent/ButtonComponent";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// Import Swiper styles
import "swiper/css";
import "swiper/css/grid";
import "swiper/css/pagination";
// import required modules
import { Grid, Pagination } from "swiper/modules";
function Category() {
  const [swiperInstance, setSwiperInstance] = useState(null);
  const [show, setShow] = useState(false);
  const [showChat, setShowChat] = useState(false);
  const handleMainSliderClick = (direction) => {
    if (swiperInstance) {
      if (direction === "prev") {
        swiperInstance.slidePrev();
      } else if (direction === "next") {
        swiperInstance.slideNext();
      }
    }
  };

  const [showAll, setShowAll] = useState(false);
  const [numToShow, setNumToShow] = useState(5);

  const toggleShowAll = () => {
    setShowAll(!showAll);
    if (showAll) {
      setNumToShow(8);
    } else {
      setNumToShow(getCategoryee?.length);
    }
  };
  const getCategoryee = [
    {
      image: process.env.PUBLIC_URL + "/assets/Homepage/Category/p1.png",
      name: "Mobiles",
    },
    {
      image: process.env.PUBLIC_URL + "/assets/Homepage/Category/p2.png",
      name: "Men's Wear",
    },
    {
      image: process.env.PUBLIC_URL + "/assets/Homepage/Category/p3.png",
      name: "Home Appliances",
    },

    {
      image: process.env.PUBLIC_URL + "/assets/Homepage/Category/p4.png",
      name: "Women's Wear",
    },
    {
      image: process.env.PUBLIC_URL + "/assets/Homepage/Category/p1.png",
      name: "Mobiles",
    },
    {
      image: process.env.PUBLIC_URL + "/assets/Homepage/Category/p2.png",
      name: "Men's Wear",
    },
    {
      image: process.env.PUBLIC_URL + "/assets/Homepage/Category/p3.png",
      name: "Home Appliances",
    },

    {
      image: process.env.PUBLIC_URL + "/assets/Homepage/Category/p4.png",
      name: "Women's Wear",
    },
  ];

  const [getCategory, setCategory] = useState();
  const { getData, IMG_URL, sellerId, type } = useContext(Context);
  const getProject = async (data) => {
    const res = await allCategory(sellerId, type);
    if (res?.success) {
      setCategory(res?.data);
    } else {
      console.error("Error fetching  data:", res?.error);
    }
  };

  const navigate = useNavigate();
  const handleNavigate = async (id) => {
    localStorage.setItem("outer5_category_id", id);
    navigate("/category");
  };
  useEffect(() => {
    getProject();
  }, [sellerId]);

  return (
    <>
      <section className="Category">
        <Container>
          <h1 className="heading-Outer-five">Categories</h1>
          <Row>
            {getCategory?.slice(0, 1).map((item, index) => (
              <Col xxl={6} xl={6} lg={12} md={12}>
                <div
                  className="product-one"
                  onClick={() => handleNavigate(item?.category_id)}
                >
                  <div className="product-image">
                    <img src={IMG_URL + item?.image} className="products" />
                  </div>
                  <div className="overlay"></div>
                  <div className="content">
                    <p className="outer-five-sub-heading">{item?.name}</p>
                  </div>
                </div>
              </Col>
            ))}
            <Col xxl={6} xl={6} lg={12} md={12}>
              <div className="Information-Slider">
                <Swiper
                  breakpoints={{
                    575: {
                      slidesPerView: 1,
                      spaceBetween: 30,
                    },
                    768: {
                      slidesPerView: 2,
                      spaceBetween: 30,
                    },

                    992: {
                      slidesPerView: 3,
                      spaceBetween: 40,
                    },
                    1199: {
                      slidesPerView: 2,
                      spaceBetween: 40,
                    },
                    1400: {
                      slidesPerView: 2,
                      spaceBetween: 40,
                    },
                  }}
                  // slidesPerView={2}
                  grid={{
                    rows: 2,
                  }}
                  spaceBetween={30}
                  pagination={{
                    clickable: true,
                  }}
                  modules={[Grid, Pagination]}
                  className="mySwiper"
                  onSwiper={(swiper) => setSwiperInstance(swiper)}
                >
                  {getCategory?.slice(1, numToShow).map((item, index) => (
                    <SwiperSlide key={index}>
                      <div
                        className="product-two"
                        onClick={() => handleNavigate(item?.category_id)}
                      >
                        <div className="product-image">
                          <img
                            src={IMG_URL + item?.image}
                            className="products"
                          />
                        </div>
                        <div className="overlay"></div>
                        <div className="content">
                          <p className="outer-five-sub-heading">{item?.name}</p>
                        </div>
                      </div>
                    </SwiperSlide>
                  ))}
                  {/* <SwiperSlide >
                    <div className="product-two" >
                      <div className="product-image">
                        <img src="" className="products" />
                      </div>
                      <div className="overlay"></div>
                      <div className="content">
                        <p className="outer-five-sub-heading"></p>
                      </div>
                    </div>
                  </SwiperSlide>
                  <SwiperSlide >
                    <div className="product-two" >
                      <div className="product-image">
                        <img src="" className="products" />
                      </div>
                      <div className="overlay"></div>
                      <div className="content">
                        <p className="outer-five-sub-heading"></p>
                      </div>
                    </div>
                  </SwiperSlide>
                  <SwiperSlide >
                    <div className="product-two" >
                      <div className="product-image">
                        <img src="" className="products" />
                      </div>
                      <div className="overlay"></div>
                      <div className="content">
                        <p className="outer-five-sub-heading"></p>
                      </div>
                    </div>
                  </SwiperSlide>
                  <SwiperSlide >
                    <div className="product-two" >
                      <div className="product-image">
                        <img src="" className="products" />
                      </div>
                      <div className="overlay"></div>
                      <div className="content">
                        <p className="outer-five-sub-heading"></p>
                      </div>
                    </div>
                  </SwiperSlide>
                  <SwiperSlide >
                    <div className="product-two" >
                      <div className="product-image">
                        <img src="" className="products" />
                      </div>
                      <div className="overlay"></div>
                      <div className="content">
                        <p className="outer-five-sub-heading"></p>
                      </div>
                    </div>
                  </SwiperSlide> */}
                </Swiper>
                {/* {showAll ? (
                  <div className="view-all mt-4" onClick={toggleShowAll}>
                    <p className='view'>View Less</p>
                  </div>
                ) : (
                  <div className="view-all mt-4" onClick={toggleShowAll}>
                    <p className='view'>View All</p>
                  </div>
                )} */}
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
}

export default Category;
