import React, { useState, useEffect, useContext } from "react";
import './OurStory.css'
import { Context } from "../../../utils/context";
import { allourstory } from "../../../utils/apis/common/Common";

const OurStory = () => {

    var HtmlToReactParser = require("html-to-react").Parser;
    var htmlToReactParser = new HtmlToReactParser();

    const [getOurstory, setOurstory] = useState();
    const { getData, IMG_URL, sellerId } = useContext(Context);
    const getProject = async (data) => {
        const res = await allourstory(sellerId);
        if (res?.success) {
            setOurstory(res?.data);
        } else {
            console.error('Error fetching  data:', res?.error);
        }
    }

    useEffect(() => {
        getProject();
    }, [sellerId]);

    return (
        <>
            <section className='our-story'>
                <div className='container'>
                    <div className='row'>
                        <div className="text-center">
                            <h1 className="hot-title">Our Story</h1>
                            <div className="title-border"></div>
                            <p className="sub-text my-4">{htmlToReactParser.parse(getOurstory?.description)}</p>
                            <div className="col-md-10 mx-auto">
                                <h5 className="h5-quote">{htmlToReactParser.parse(getOurstory?.description_two)}</h5>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default OurStory