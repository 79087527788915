import React, { useState, useEffect, useContext } from "react";
import FirstVender from './ProductDetailspage/FirstVender'
import CategoryTabs from './CAtegoryTabs/CategoryTabs'
import FrequentlyCard from './FrequentlyCard/FrequentlyCard'
import SupplierCard from './SupplierCard/SupplierCard'
import { useParams } from "react-router-dom";
import { Context } from "../../utils/context";

const CategoryProductpage = (headingName) => {
    const { IMG_URL, userdata, getData, deleteData, sellerId } = useContext(Context);
    const [product, setproduct] = useState([]);
    const [category, setCategory] = useState("");
    const { id } = useParams();
    console.log(id, "proid");
    const getProductDetails = async () => {
        const res = await getData(`/info-website/home/all-product-details?seller_id=${sellerId}&id=${id}`);
        if (res?.success) {
            setproduct(res?.data)
        }
    }
    useEffect(() => {
        getProductDetails();
    }, [sellerId]);
    return (
        <>

            <FirstVender />
            
            <CategoryTabs product={product} />
            {/* <FrequentlyCard headingName="Frequently bought together" /> */}

            
            <SupplierCard headingName="Supplier's popular products" product={product} />
        </>
    )
}

export default CategoryProductpage