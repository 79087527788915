import React from 'react'
import CategoryBanner from './category-banner/CategoryBanner'
import CategoryCard from './category-banner/Category-card/CategoryCard'

const Category = () => {
  return (
    <>
     <CategoryBanner/>
     <CategoryCard/>
    </>
  )
}

export default Category