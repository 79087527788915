import React, { useState } from "react";
import './Payment_checkout.css'
import Shipping_information from "./step1/Shipping_information";
import Payment_information from "./step2/Payment_information";
import Order_review from "./step3/Order_review";


import { Form } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck, } from "@fortawesome/free-solid-svg-icons";
import BreadcrumComponent from "../Common_Component/BreadcrumComponent/BreadcrumComponent";

const Payment_checkout = () => {

    const [current, setCurrent] = useState(1);
    const stepLabels = [
        { id: 1, label: "Shipping Information" },
        { id: 1, label: "Payment Information" },
        { id: 1, label: "Order Review" },
    ]


    const handleNext = () => {
        if (current < 4) {
            setCurrent(current + 1);
        }
    };

    const handlePrevious = () => {
        if (current > 1) {
            setCurrent(current - 1);
        }
    };

    const isStepCompleted = (step) => {
        return step < current;
    };

    const breadcrumbItems = [
        { text: "Back", link: "/product-cart-inner" , className:"active" }
    ];


    return (
        <>
            <section className='Payment_checkout-section'>
                <div className="container">
                    <BreadcrumComponent items={breadcrumbItems} />
                    <div className="">
                        <section className="Payment_stepform_sec">
                            <Form>
                                <div className="signup-form ">
                                    <h4 className="stepform-heading">Checkout</h4>
                                    <div className="progress-bar-container">
                                        <div className="step-row">

                                            {stepLabels.map((item, index) => (
                                                <div key={index + 1} className={`step-container ${index + 1 === current ? "active" : ""}`}>
                                                    <div className="main-flex-div">
                                                        <div className="circle-center">
                                                            <div
                                                                className={`line ${isStepCompleted(index + 1)
                                                                    ? "completed"
                                                                    : ""
                                                                    } ${index + 1 === current ? "active" : ""}`}
                                                            >
                                                                <FontAwesomeIcon icon={faCheck} className="checkIcon" />
                                                            </div>
                                                        </div>
                                                        <div className="centerDivcircle">
                                                            <span className={`step-text ${isStepCompleted(index + 1) ? "completed" : ""} ${index + 1 === current ? "active" : ""}`}>{item.label}</span>
                                                        </div>
                                                    </div>
                                                    <div className='circle-center'>
                                                        <div className={`circle ${isStepCompleted(index + 1) ? "completed" : ""}`}></div>
                                                    </div>

                                                </div>
                                            ))}
                                        </div>
                                    </div>
                                </div>
                                <div className="wizard-form mt-3">
                                    {current === 1 && (
                                        <Shipping_information handleNext={handleNext} />
                                    )}
                                    {current === 2 && (
                                        <Payment_information
                                            handleNext={handleNext}
                                            handlePrevious={handlePrevious}
                                        />
                                    )}
                                    {current === 3 && (
                                        <Order_review
                                            handleNext={handleNext}
                                            handlePrevious={handlePrevious}
                                        />
                                    )}
                                </div>
                            </Form>
                        </section>
                    </div>

                </div>
            </section>
        </>
    )
}

export default Payment_checkout