import React, { useEffect, useState, useContext } from "react";
import "./Header.css";
import { Col, Container, Dropdown, InputGroup, Nav, NavDropdown, Navbar, Row } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link, useLocation } from "react-router-dom";
import { faHeart, faHeart as farHeart } from "@fortawesome/free-regular-svg-icons";
import { faShoppingCart, faSearch } from "@fortawesome/free-solid-svg-icons";
import { faUser } from "@fortawesome/free-solid-svg-icons";
import Form from "react-bootstrap/Form";
import Buttons from "../../Common_Component/Buttons/Buttons";
import { Context } from "../../../utils/../utils/context";
import { useForm, Controller, SubmitHandler } from "react-hook-form";

import {GetWebHeader , GetAppSetup} from "../../../utils/apis/master/Master"
import { useNavigate } from "react-router-dom";
import { Category } from "../../../utils/apis/Product/Product";
const Header = () => {
  const { IMG_URL, userdata, getData, postData, deleteData, sellerId } = useContext(Context);
  const [category, setCat] = useState([]);
  const [inputValue, setInputValue] = useState('');
  const navigate = useNavigate();
  const handleInputChange = (event) => {
    setInputValue(event.target.value);
  };
  const [selectedCategory, setSelectedCategory] = useState('All Categories');
  const [selectedCategoryId, setSelectedCategoryId] = useState('All Categories');
  const handleSelect = (eventKey) => {
    setSelectedCategory(category.find(cat => cat.id.toString() === eventKey).name);
    setSelectedCategoryId(category.find(cat => cat.id.toString() === eventKey).category_id);
  };
  const handleMobileSelect = (eventKey) => {
    setSelectedCategoryId(category.find(cat => cat.id.toString() === eventKey).id);
    localStorage.setItem('outer5_category_id', category.find(cat => cat.id.toString() === eventKey).id);
    navigate(`/category`);
  };
  const {
    control,
    register,
    handleSubmit,
    getValues,
    setError,
    setValue,
    clearErrors,
    formState: { errors },
    reset,
  } = useForm();

  const location = useLocation();

  const [activeItem, setActiveItem] = useState(() => {
    return localStorage.getItem("activeItem") || "home";
  });
  
  const getCat = async () => {
    const res = await Category(sellerId);
    if (res?.success) {
      setCat(res?.data)
    }
  };

  useEffect(() => {
    getCat();
    const path = location.pathname.substr(1);
    updateActiveItem(path || "home");
  }, [location,sellerId]);

  const updateActiveItem = (newActiveItem) => {
    localStorage.setItem("activeItem", newActiveItem);
    setActiveItem(newActiveItem);
  };


  const [activeLink, setActiveLink] = useState("home");
  useEffect(() => {
    const path = location.pathname.substring(1);
    setActiveLink(path || "home");
  }, [location.pathname]);



  const handleNavItemClick = (selectedLink) => {
    if (selectedLink === 'category') {
      localStorage.removeItem('outer5_category_id');
      localStorage.removeItem('outer5_prodName');
    }
    setActiveLink(selectedLink);
    const navbarToggle = document.querySelector(".navbar-toggler");
    if (navbarToggle && window.innerWidth <= 992) {
      navbarToggle.click();
    }
  };



  const handelSearch = async () => {
    if(inputValue){
      localStorage.setItem('outer5_prodName', inputValue);
    }
    if(selectedCategoryId){
      localStorage.setItem('outer5_category_id', selectedCategoryId);
    }
    navigate(`/category`);
  }



  const [headers, setHeaders] = useState([]);
  const getHeader = async () => {
    const res = await GetWebHeader(sellerId);
    if (res?.success) {
      setHeaders(res?.data);
    }
  };

  const [appSetup, setAppSetup] = useState();
  const getAppSetup = async () => {
    const res = await GetAppSetup(sellerId);
    if (res?.success) {
      setAppSetup(res?.data);
    }
  };

  useEffect(() => {
    getHeader();
    getAppSetup();
  }, [sellerId]);
  return (
    <>
      <section className="header-section" style={{
        background:appSetup?.header_color
      }}>
        <Container fluid className="p-0">
          <Container>
            <div className="header-main">
              <Row>
                <Col lg={2} md={3} sm={2} xs={4}>
                  <div className="header-img mt-lg-2 mt-md-2 mt-sm-2 mt-2">
                    <Link to={"/"}>
                      <img
                        className="header-logo"
                        src={
                          IMG_URL +
                          appSetup?.image
                        }
                        alt="Logo"
                      />
                    </Link>
                  </div>
                </Col>
                <Col lg={8} md={7} sm={10} xs={8}>
                  <div className="search-bar my-lg-4  my-md-4 my-sm-3 my-4">
                    <InputGroup className="mb-3 ">
                      <InputGroup.Text className="drop-all-category" id="basic-addon2">
                        <Dropdown onSelect={handleSelect}>
                          <Dropdown.Toggle id="dropdown-basic">
                            {selectedCategory}
                            <div className="image-category">
                              <img className="drop-img" src={process.env.PUBLIC_URL + "/assets/Icon/drop-icon.png"} alt="icon" />
                            </div>
                          </Dropdown.Toggle>
                          <Dropdown.Menu>
                            {category?.map((val) => (
                              <Dropdown.Item key={val.id} eventKey={val.id.toString()}>
                                {val.name}
                              </Dropdown.Item>
                            ))}
                          </Dropdown.Menu>
                        </Dropdown>
                      </InputGroup.Text>
                      <Form.Control
                        className="search-bar-field"
                        type="text"
                        placeholder="Search Products"
                        aria-label="Search Products"
                        aria-describedby="basic-addon2"
                        value={inputValue}
                        onChange={handleInputChange}
                      />
                      <InputGroup.Text id="basic-addon2" className="search-icon-area" onClick={handelSearch}><FontAwesomeIcon icon={faSearch} /></InputGroup.Text>
                    </InputGroup>
                  </div>
                </Col>
                <Col lg={2} md={2}>
                  <Link to={"/contact-us"}>
                    <div className="button-holder text-end my-lg-4  my-md-4 ">
                      <Buttons type="button" className={'contact-btn'} text="Contact Us"></Buttons>
                    </div></Link>
                </Col>
              </Row>
            </div>

          </Container>
          <div className="second-header">

            <Navbar collapseOnSelect expand="lg" className=" p-0 m-0">
              <Container>
                <Navbar.Toggle aria-controls="responsive-navbar-nav" />
                <Navbar.Collapse id="responsive-navbar-nav">

                  <Nav className="mx-auto  navbMainOne">

                    <Nav.Link as={Link} to="/" active={activeItem === "home"} onClick={() => handleNavItemClick("home")}
                      className={`nav-link-with-borders ${activeLink === "home" ? "active" : ""}`}style={{ color: appSetup?.header_font_color }}
                    > Home
                      <div className="d-flex">
                        <div className="blue-border"></div>
                        <div className="orange-border"></div>
                      </div>
                    </Nav.Link>

                    <Nav.Link as={Link} to="/category" active={activeItem === "category"} onClick={() => handleNavItemClick("category")}
                      className={`nav-link-with-borders ${activeLink === "category" ? "active" : ""}`}style={{ color: appSetup?.header_font_color }}
                    > Category
                      <div className="d-flex">
                        <div className="blue-border"></div>
                        <div className="orange-border"></div>
                      </div>
                    </Nav.Link>


                    <Nav.Link as={Link} to="/about-us" active={activeItem === "about-us"} onClick={() => handleNavItemClick("about-us")}
                      className={`nav-link-with-borders ${activeLink === "about-us" ? "active" : ""}`}style={{ color: appSetup?.header_font_color }}
                    > About Us
                      <div className="d-flex">
                        <div className="blue-border"></div>
                        <div className="orange-border"></div>
                      </div>
                    </Nav.Link>


                    <Nav.Link as={Link} to="/faq" active={activeItem === "faq"} onClick={() => handleNavItemClick("faq")}
                      className={`nav-link-with-borders ${activeLink === "faq" ? "active" : ""}`}style={{ color: appSetup?.header_font_color }}
                    > FAQs
                      <div className="d-flex">
                        <div className="blue-border"></div>
                        <div className="orange-border"></div>
                      </div>
                    </Nav.Link>

                    {/* <Nav.Link as={Link} to="/blog" active={activeItem === "blog"} onClick={() => handleNavItemClick("blog")}
                      className={`nav-link-with-borders ${activeLink === "blog" ? "active" : ""}`}
                    > Blog
                      <div className="d-flex">
                        <div className="blue-border"></div>
                        <div className="orange-border"></div>
                      </div>
                    </Nav.Link> */}

                  </Nav>

                  <div className="mobile-view-dropdown">
                    <Dropdown onSelect={handleSelect}>
                      <Dropdown.Toggle id="dropdown-basic">
                        {selectedCategory}
                        <div className="image-category">
                          <img className="drop-img" src={process.env.PUBLIC_URL + "/assets/Icon/drop-icon.png"} alt="icon" />
                        </div>
                      </Dropdown.Toggle>
                      <Dropdown.Menu>
                        {category?.map((val) => (
                          <Dropdown.Item key={val.id} eventKey={val.id.toString()}>
                            {val.name}
                          </Dropdown.Item>
                        ))}
                      </Dropdown.Menu>
                    </Dropdown>
                  </div>

                  <Link as={Link} to="/contact-us" active={activeItem === "contact-us"} onClick={handleNavItemClick}>
                    <div className="button-holder-mobile-view ">
                      <Buttons type="button" className={'contact-btn'} text="Contact Us"></Buttons>
                    </div></Link>
                  {/* <div className="all-icon ms-auto">

                    <Link to={"/product-cart-inner"}>
                      <FontAwesomeIcon icon="fa-solid fa-cart-shopping" className="cart-logo" onClick={handleNavItemClick} />
                    </Link>
                    <Link to={"/wishlist"}>
                      <FontAwesomeIcon icon={faHeart} className="cart-logo" onClick={handleNavItemClick} />
                    </Link>
                    <Link to={"/my-account"}>
                      <FontAwesomeIcon icon="fa-solid fa-user" className="cart-logo" onClick={handleNavItemClick} />
                    </Link>
                    <Link to={"/login"} className="login">Login</Link>

                  </div> */}
                </Navbar.Collapse>
              </Container>
            </Navbar>
          </div>


        </Container>
      </section>
    </>
  );
};

export default Header;
