import React, { useState, useEffect, useContext } from "react";
import "./CategoryBanner.css";
import { Context } from "../../../utils/context";
import { categoryBanner } from "../../../utils/apis/common/Common";
const CategoryBanner = () => {
  const [getCategoryBanner, setCategoryBanner] = useState();
  const { getData, IMG_URL, sellerId } = useContext(Context);
  const getProject = async (data) => {
    const res = await categoryBanner(sellerId);
    if (res?.success) {
      setCategoryBanner(res?.data);
    } else {
      console.error("Error fetching  data:", res?.error);
    }
  };

  useEffect(() => {
    getProject();
  }, [sellerId]);
  return (
    <>
      <section className="category-banner">
        <div className="image-banner">
          <img
            className="category-img"
            src={IMG_URL + getCategoryBanner?.image}
            alt="category-img"
          />
        </div>
      </section>
    </>
  );
};

export default CategoryBanner;
