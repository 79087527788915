import React, { useRef, useState, useEffect, useContext } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import { Pagination } from "swiper/modules";
import { Col, Container, Row } from "react-bootstrap";
import ProductCards from "../../Common_Component/ProductCards/ProductCards";
import "./TrendingProducts.css";
import Buttons from "../../Common_Component/Buttons/Buttons";
import { Link } from "react-router-dom";
import { Context } from "../../../utils/context";
import { trendingProducts, afterTrendingProducts } from "../../../utils/apis/common/Common";

function TrendingProducts() {

    const [getTrendingProducts, setTrendingProducts] = useState();
    const [getAfterTrendingProducts, setAfterTrendingProducts] = useState();
    const { getData, IMG_URL, sellerId } = useContext(Context);
    
    const getProject = async (data) => {
        const res = await trendingProducts(sellerId);
        if (res?.success) {
            setTrendingProducts(res?.data);
        } else {
            console.error('Error fetching  data:', res?.error);
        }
    }
    const getProjectTwo = async (data) => {
        const res = await afterTrendingProducts(sellerId);
        if (res?.success) {
            setAfterTrendingProducts(res?.data);
        } else {
            console.error('Error fetching  data:', res?.error);
        }
    }

    useEffect(() => {
        getProject();
        getProjectTwo();
    }, [sellerId]);
    return (
        <>
            <section className="Trending-Products">
                <Container>
                    <h1 className="heading-Outer-five">Our Trending Products</h1>
                    <div className="Swiper-Product-one">
                        <Swiper
                            pagination={{
                                dynamicBullets: true,
                            }}
                            modules={[Pagination]}
                            className="mySwiper"
                            breakpoints={{
                                280: {
                                    slidesPerView: 1,
                                    spaceBetween: 30,
                                },
                                320: {
                                    slidesPerView: 1,
                                    spaceBetween: 10,
                                },
                                485: {
                                    slidesPerView: 1.5,
                                    spaceBetween: 10,
                                },
                                575: {
                                    slidesPerView: 2,
                                    spaceBetween: 10,
                                },
                                768: {
                                    slidesPerView: 2,
                                    spaceBetween: 10,
                                },
                                992: {
                                    slidesPerView: 3,
                                    spaceBetween: 10,
                                },
                                1200: {
                                    slidesPerView: 3.5,
                                    spaceBetween: 20,
                                },
                                1400: {
                                    slidesPerView: 4,
                                    spaceBetween: 20,
                                },
                            }}
                        >
                            {getTrendingProducts?.map((item, index) => (
                                index % 2 == 0 && (
                                    <SwiperSlide key={index}>
                                        <ProductCards images={IMG_URL + item?.thumbnail}
                                            imgClassName="demo"
                                            cardtitle={item?.product_title + ", " + item?.product_generic_name}
                                            prodId={item?.id} />
                                    </SwiperSlide>
                                )
                            ))}
                        </Swiper>
                    </div>
                    <div className="Swiper-Product-Two">
                        <Swiper
                            pagination={{
                                dynamicBullets: true,
                            }}
                            modules={[Pagination]}
                            className="mySwipertwo"
                            breakpoints={{
                                280: {
                                    slidesPerView: 1,
                                    spaceBetween: 30,
                                },
                                320: {
                                    slidesPerView: 1,
                                    spaceBetween: 30,
                                },
                                768: {
                                    slidesPerView: 2,
                                    spaceBetween: 20,
                                },
                                992: {
                                    slidesPerView: 2,
                                    spaceBetween: 20,
                                },
                                1200: {
                                    slidesPerView: 2,
                                    spaceBetween: 20,
                                },
                                1600: {
                                    slidesPerView: 2,
                                    spaceBetween: 10,
                                },
                            }}
                        >
                            {getAfterTrendingProducts?.map((item, index) => (

                                index % 2 != 0 && (
                                    <SwiperSlide key={index}>
                                        <div className="Products-cards-two">
                                            <div className="Main">
                                                <div className="image">
                                                    <img src={IMG_URL + item?.thumbnail} className="main-img" />
                                                </div>
                                                <div className="content">
                                                    <p className="text-outer-five">{item?.product_title + ", " + item?.product_generic_name}</p>
                                                    <Link to={`/category-product/${item?.id}`}>
                                                        <div className="view-details">
                                                            <div className="ProductCards2">
                                                                <button className="click-btn btn-style511">
                                                                    <span>VIEW DETAILS</span>
                                                                </button>
                                                            </div>
                                                        </div>
                                                    </Link>
                                                </div>
                                            </div>
                                        </div>
                                    </SwiperSlide>
                                )
                            ))}
                        </Swiper>
                    </div>
                </Container>
            </section>
        </>
    );
}
export default TrendingProducts;
