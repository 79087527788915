import React, { useState, useEffect, useContext } from "react";
import "./ContactUs.css"
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import { useForm, Controller, SubmitHandler } from "react-hook-form";
import Col from "react-bootstrap/Col";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import classNames from "classnames";

import { Link } from "react-router-dom";
import Buttons from "../Common_Component/Buttons/Buttons"
import { Container } from "react-bootstrap";
import { Context } from "../../utils/context";
import { postContactUs } from "../../utils/apis/common/Common";
import Success_Modalss from "../Common_Component/Success_Modal/Success_Modalss";
import { GetAppSetup, GetSocialMedia } from "../../utils/apis/master/Master";

const ContactUs = () => {
  console.log("asdasdsa");
  const [mapSrc, setMapSrc] = useState("https://maps.google.com/maps?width=600&height=400&hl=en&q=pune&t=&z=14&ie=UTF8&iwloc=B&output=embed");
  const { getData, IMG_URL, Select2Data, sellerId } = useContext(Context);
  const { control, register, handleSubmit, getValues, setError, clearErrors, formState: { errors }, reset, watch, trigger, setValue } = useForm();
  const [successModalShow, setSuccessModalShow] = useState(false);

  const onSubmit = async (data) => {
    data.seller_id = sellerId;
    
    const res = await postContactUs(data)

    if (res?.success) {
      setSuccessModalShow(true);
      setTimeout(() => {
        setSuccessModalShow(false);
        reset();
      }, 1000);
    } else {
      console.error('Error fetching  data:', res?.error);
    }
  }

  const [appSetup, setAppSetup] = useState();
  const getAppSetup = async () => {
    const res = await GetAppSetup(sellerId);
    if (res?.success) {
      setAppSetup(res?.data);
      setMapSrc(`https://maps.google.com/maps?width=600&height=400&hl=en&q=${res?.data?.address}&t=&z=14&ie=UTF8&iwloc=B&output=embed`);
    }
  };

  const [socialMedia, setSocialMedia] = useState();
  const getSocialLink = async () => {
    const res = await GetSocialMedia(sellerId);
    if (res?.success) {
      setSocialMedia(res?.data);
    }
  };

  useEffect(() => {
    getSocialLink();
    getAppSetup();
  }, [sellerId]);
  
  return (
    <>
      <section className="contact-us">
        <div className="map-section">
          <div className="mapouter">
            <div className="gmap_canvas">
              <iframe
                className="gmap_iframe"
                frameborder="0"
                scrolling="no"
                marginheight="0"
                marginwidth="0"
                src={mapSrc}
              ></iframe>
              <a href="https://pdflist.com/" alt="pdflist.com"></a>
            </div>
          </div>
        </div>
        <Container>

          <Row className="mt-5">
            <div className="heading-holder mb-4">
              <h1>Have a question or comment? </h1>
              <p>Use the form below to send us a message or contact us by mail at:</p>
            </div>
            <Col lg={8} md={8}>

              <div className="form-start">
                <Form>
                  <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                    <Form.Control
                      type="text"
                      name="name"
                      placeholder="Enter Name"
                      {...register("name", {
                        required: "Name is required",
                      })}
                      className={classNames("", {
                        "is-invalid": errors?.name,
                      })}
                      onKeyDown={(event) => {
                        if (!/[A-Z-a-z ]/.test(event.key)) {
                          event.preventDefault();
                        }
                      }}
                    />
                  </Form.Group>
                  <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                    <Form.Control
                      type="text"
                      name="email"
                      placeholder="E-mail Address"
                      {...register("email", {
                        required: "Email Id required",
                        pattern: {
                          value: /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Z|a-z]{2,}$/,
                          message: "Email Id Invalid",
                        },
                      })}
                      className={classNames("", {
                        "is-invalid": errors?.email,
                      })}
                    />
                  </Form.Group>
                  <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                    <Form.Control
                      type="text"
                      name="subject"
                      placeholder="Subject"
                      {...register("subject", {
                        required: "subject is required",
                      })}
                      className={classNames("", {
                        "is-invalid": errors?.subject,
                      })}
                    />
                  </Form.Group>
                  <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
                    <Form.Control
                      as="textarea"
                      name="message"
                      type="text"
                      rows={3}
                      placeholder="Enter Message"
                      {...register("message", {
                        required: "Message required",
                      })}
                      className={classNames("", {
                        "is-invalid": errors?.message,
                      })}
                    />
                    <Form.Control
                      type="hidden"
                      name="seller_id"
                      value="6"
                    />
                  </Form.Group>
                  <button className=" click-btn btn-style511" type="submit" onClick={handleSubmit(onSubmit)} > <span>Submit</span></button>

                </Form>
              </div>
            </Col>
            <Col lg={4} md={4}>
              <div className="heading-holder mb-4">
                <h2>Get In Touch!</h2>
                <h3>We'd love to hear from you -</h3>
                <span>please use the form to send us your message or ideas. Or simply pop in for a cup of fresh tea and a cookie:</span>
              </div>
              <div className="text-holder">
                <FontAwesomeIcon icon="fa-solid fa-envelope" className="me-3" /><span>{appSetup?.contact_no}</span>

              </div>
              <div className="text-holder">
                <FontAwesomeIcon icon="fa-solid fa-message" className="me-3" /><span>{appSetup?.email}</span>
              </div>
              <div className="social-icon mt-3">
                <img className="sociallogo me-2" src={process.env.PUBLIC_URL + "/assets/Icon/facebook1.png"} alt="sociallogo" />
                <img className="sociallogo me-2" src={process.env.PUBLIC_URL + "/assets/Icon/linkdin1.png"} alt="sociallogo" />
                <img className="sociallogo me-2" src={process.env.PUBLIC_URL + "/assets/Icon/twiter1.png"} alt="sociallogo" />
                <img className="sociallogo" src={process.env.PUBLIC_URL + "/assets/Icon/youtube1.png"} alt="sociallogo" />
              </div>
            </Col>
          </Row>
        </Container>
      </section>
      <Success_Modalss show={successModalShow} texts={'Form Submitted Successfully'} />
    </>
  );
};

export default ContactUs;
