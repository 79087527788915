import React, { useState, useEffect, useContext } from "react";
import "./CategoryCard.css";
import { Col, Container, Nav, Row, Tab } from "react-bootstrap";
import CategoryProductCard from "./CategoryProductCard/CategoryProductCard";
import { Context } from "../../../../utils/context";
import { allCategory } from "../../../../utils/apis/common/Common";
import {
  AllChildCategory,
  AllSubCategory,
  Category,
  Products,
} from "../../../../utils/apis/Product/Product";
import { useParams } from "react-router-dom";

import { Swiper, SwiperSlide } from "swiper/react";
import Veggies_Section_Card from "../../../../Component/Common_Component/Veggies_Section_Card/Veggies_Section_Card";
import { ShimmerButton } from "react-shimmer-effects";
import { ShimmerThumbnail } from "react-shimmer-effects";
import { ShimmerPostItem } from "react-shimmer-effects";

const CategoryCard = () => {
  const [getCategory, setCategory] = useState();
  const [activeCategory, setActiveCategory] = useState("");
  const { getData, IMG_URL, sellerId, type } = useContext(Context);
  const [subCategoryLoder, setSubCategoryLoder] = useState(false);
  const [childCategoryLoder, setChildcategoryLoder] = useState(false);
  const [productLoder, setProductLoder] = useState(false);
  const getProject = async (data) => {
    const res = await Category(sellerId, type);
    if (res?.success) {
      setCategory(res?.data);
      const storedCategory = localStorage.getItem("outer5_category_id");
      console.log(storedCategory, 'stored');
      if (storedCategory !== null) {
        getSubCategory(storedCategory, storedCategory);
      } else {
        getSubCategory(res?.data[0]?.category_id, res?.data[0]?.category_id);
      }
    } else {
      console.error("Error fetching  data:", res?.error);
    }
  };

  const [activeSubCategory, setActiveSubCategory] = useState("");
  const [subCategory, setSubCategory] = useState([]);
  const getSubCategory = async (category_id, seller_cat_id) => {
    setSubCategoryLoder(true)
    setActiveCategory(category_id);
    const res = await AllSubCategory(sellerId, category_id);
    if (res?.success) {

      setChildCategory([]);
      setSubCategory(res?.data);
      setSubCategoryLoder(false)
      if (res?.data?.[0]?.sub_category_id) {
        getChildCategory(
          res?.data?.[0]?.sub_category_id,
          res?.data?.[0]?.sub_category_id
        );
        await getProduct(category_id, res?.data?.[0]?.sub_category_id);
      } else {
        await getProduct(category_id);
      }
    }
  };

  const [activeChildCategory, setActiveChildCategory] = useState("");
  const [childCategory, setChildCategory] = useState([]);
  const getChildCategory = async (sub_category_id, seller_cat_id) => {
    setChildcategoryLoder(true);
    setActiveSubCategory(sub_category_id);
    const res = await AllChildCategory(sellerId, sub_category_id);
    if (res?.success) {
      setChildCategory(res?.data);
      setChildcategoryLoder(false);
      console.log(res?.data);
      if (res?.data?.[0]?.child_category_id) {
        await getProduct(
          res?.data?.[0]?.category_id,
          res?.data?.[0]?.sub_category_id,
          res?.data?.[0]?.child_category_id
        );
      } else {
        await getProduct(activeCategory, sub_category_id);
      }
    }
  };

  const [products, setProducts] = useState([]);
  const getProduct = async (
    category_id = "",
    sub_category_id = "",
    child_category_id = ""
  ) => {
    setProductLoder(true)
    const storedName = localStorage.getItem("outer5_prodName");
    const res = await Products(
      category_id,
      sub_category_id,
      child_category_id,
      sellerId,
      storedName
    );
    if (res?.success) {
      setProducts(res?.data);
      setProductLoder(false)
    }
  };

  useEffect(() => {
    getProject();
    const storedCategory = localStorage.getItem("outer5_category_id");
    if (storedCategory) {
      getProduct(storedCategory);
    }
  }, [sellerId]);

  useEffect(() => {
    getProject();
    const storedCategory = localStorage.getItem("outer5_category_id");
    if (storedCategory) {
      getProduct(storedCategory);
    }
  }, [
    localStorage.getItem("outer5_category_id"),
    localStorage.getItem("outer5_prodName"),
  ]);

  const [swiperInstance, setSwiperInstance] = useState(null);
  const [activeHearts, setActiveHearts] = useState([]);

  const handleMainSliderClick = (direction) => {
    if (swiperInstance) {
      if (direction === "prev") {
        swiperInstance.slidePrev();
      } else if (direction === "next") {
        swiperInstance.slideNext();
      }
    }
  };

  const handleMainSliderClick2 = (direction) => {
    if (swiperInstance) {
      if (direction === "prev") {
        swiperInstance.slidePrev();
      } else if (direction === "next") {
        swiperInstance.slideNext();
      }
    }
  };
  console.log(subCategoryLoder, "subCategoryLoder");
  return (
    <>
      <section className="category-cards">
        <Container>
          <Row className="mb-5">
            <Col xxl={3} xl={3} lg={4} md={6}>
              <div className="heading-holder">
                <h1 className="heading-Outer-five">Categories</h1>
              </div>
            </Col>
            <Col xxl={9} xl={9} lg={8} md={6}>
              <div className="border-line mt-3"></div>
            </Col>
          </Row>

          <Tab.Container id="left-tabs-example" defaultActiveKey="first">
            <Row>
              <Col lg={3} md={4} sm={4}>
                <div className="scroller mb-5">
                  <Nav variant="pills" className="flex-column ">
                    {getCategory?.map((data, index) => (
                      <Nav.Item>
                        <Nav.Link
                          className={
                            activeCategory == data?.category_id ? "active" : ""
                          }
                          onClick={() =>
                            getSubCategory(data?.category_id, data?.category_id)
                          }
                        >
                          {data?.name}
                        </Nav.Link>
                      </Nav.Item>
                    ))}
                  </Nav>
                </div>
              </Col>
              <Col lg={9} md={8} sm={8}>
                <div className="subcategorytabss">
                  <Tab.Container
                    id="left-tabs-example"
                    defaultActiveKey="vegfirst"
                  >
                    <Row className="vegetable-sec">
                    
                      <Col lg={3} md={4} sm={6} className="mb-4">
                        <div className="bg-color">
                          <div className="product-name">
                            {subCategoryLoder === false && (
                              <h5 className="">Subcategory</h5>
                            )}
                          </div>
                          <Nav variant="pills" className="flex-column">

                            {subCategoryLoder === false ? (

                              subCategory?.map((value, index) => (
                                <Nav.Item key={index}>
                                  <Nav.Link
                                    className={
                                      activeSubCategory === value?.sub_category_id
                                        ? "active"
                                        : ""
                                    }
                                    onClick={() =>
                                      getChildCategory(
                                        value?.sub_category_id,
                                        value?.sub_category_id
                                      )
                                    }
                                  >

                                    {value?.name}
                                  </Nav.Link>
                                </Nav.Item>
                              ))

                            ) : (
                              <>
                                <ShimmerButton size="lg" />
                                <ShimmerButton size="lg" />
                                <ShimmerButton size="lg" />
                                <ShimmerButton size="lg" />
                              </>
                            )}
                          </Nav>
                        </div>
                      </Col>

                      <Col
                        lg={9}
                        md={8}
                        className="px-lg-0 px-md-0 px-sm-none px-none pb-5"
                      >
                        <Tab.Content className="subcat-tabcobt">
                          <Tab.Pane eventKey="vegfirst">
                            <Row>
                              <Col
                                lg={12}
                                md={12}
                                sm={12}
                                className="px-lg-0 px-md-0 px-sm-none px-none"
                              >
                                <Swiper
                                  breakpoints={{
                                    0: {
                                      slidesPerView: 1,
                                      spaceBetween: 0,
                                    },
                                    380: {
                                      slidesPerView: 2,
                                    },
                                    575: {
                                      slidesPerView: 3,
                                    },
                                    768: {
                                      slidesPerView: 3,
                                    },
                                    992: {
                                      slidesPerView: 4,
                                    },
                                    1200: {
                                      slidesPerView: 4,
                                    },
                                    1400: {
                                      slidesPerView: 4,
                                      spaceBetween: 0,
                                    },
                                  }}
                                  navigation={false}
                                  className="mySwiper"
                                  onSwiper={(swiper) =>
                                    setSwiperInstance(swiper)
                                  }
                                >


                                  {childCategoryLoder === false ? (
                                    // If subCategoryLoader is false, render the child categories
                                    <>
                                      {childCategory?.map((value, index) => (
                                        <SwiperSlide key={index}>
                                          <Veggies_Section_Card child={value} product={getProduct} />
                                        </SwiperSlide>
                                      ))}
                                    </>
                                  ) : (
                                    // If subCategoryLoader is true, render the shimmer buttons
                                    <>
                                      <ShimmerThumbnail height={250} rounded />
                                      <ShimmerThumbnail height={250} rounded/>
                                      <ShimmerThumbnail height={250} rounded />
                                      
                                    </>
                                  )}

                                  {/* {childCategory?.map((value, index) => (
                                    <SwiperSlide>
                                      <Veggies_Section_Card child={value} product={getProduct} />
                                    </SwiperSlide>
                                  ))} */}

                                </Swiper>
                                {childCategory.length > 0 && (
                                  <div class="silder-btn">
                                    <div
                                      className="back-btn"
                                      onClick={() =>
                                        handleMainSliderClick("prev")
                                      }
                                    >
                                      {" "}
                                      <img
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/assets/Icon/left.png"
                                        }
                                      />{" "}
                                    </div>
                                    <div
                                      className="next-btn"
                                      onClick={() =>
                                        handleMainSliderClick("next")
                                      }
                                    >
                                      <img
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/assets/Icon/right.png"
                                        }
                                      />{" "}
                                    </div>
                                  </div>
                                )}
                              </Col>
                            </Row>
                          </Tab.Pane>
                        </Tab.Content>
                      </Col>
                    </Row>
                  </Tab.Container>
                </div>

                <Tab.Content>
                  {productLoder === false ? (
                 
                    <>
                      <Tab.Pane eventKey="first">
                        <CategoryProductCard products={products} />
                      </Tab.Pane>
                    </>
                  ) : (
                   
                    <>
                      <ShimmerPostItem size="lg" />
                      <ShimmerPostItem size="lg" />
                      <ShimmerPostItem size="lg" />             
                    </>
                  )}
                  {/* <Tab.Pane eventKey="first">
                    <CategoryProductCard products={products} />
                  </Tab.Pane> */}
                  {/* <Tab.Pane eventKey="second">
                                        <CategoryProductCard />
                                    </Tab.Pane>
                                    <Tab.Pane eventKey="third">First tab content</Tab.Pane>
                                    <Tab.Pane eventKey="fourth">Second tab content</Tab.Pane>
                                    <Tab.Pane eventKey="fifth">First tab content</Tab.Pane>
                                    <Tab.Pane eventKey="six">Second tab content</Tab.Pane>
                                    <Tab.Pane eventKey="seven">First tab content</Tab.Pane>
                                    <Tab.Pane eventKey="eight">Second tab content</Tab.Pane>
                                    <Tab.Pane eventKey="nine">First tab content</Tab.Pane>
                                    <Tab.Pane eventKey="ten">Second tab content</Tab.Pane>
                                    <Tab.Pane eventKey="eleven">First tab content</Tab.Pane>
                                    <Tab.Pane eventKey="twelve">Second tab content</Tab.Pane> */}
                </Tab.Content>
              </Col>
            </Row>
          </Tab.Container>
        </Container>
      </section>
    </>
  );
};

export default CategoryCard;
